import React from "react";
import { Link } from "gatsby";

const ShortTitles = props => {
    return (
        <div className="team-item team-item1">
            <div className="team__img">
                <Link to={props.blogContent.TitleLink}> <img src={props.blogContent.ImagePath} alt="avatar" /> </Link>
                <div className="team__img-links">
                    <ul>
                        {props.blogContent.SocialLinks.map(item => (
                            <li>
                                <Link to={item.Link}> <i className={item.IconName}></i> </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="team__title">
                <h3 className="team__title-title">
                    <Link to={props.blogContent.TitleLink}> {props.blogContent.Title} </Link>
                </h3>
                {/* <span className="team__title-meta">{props.blogContent.Description}</span> */}
            </div>
        </div>
    );
};

export default ShortTitles;
