import React from 'react';
import Layout from "../../components/Layout";
import NavOne from "../../components/NavOne";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import JfmContributors from "../../components/contributors/JfmContributors";

const TeamPage = () => {
    return (
        <Layout pageTitle="JCI India Foundation | JFM Contributors">
            <NavOne />
            <PageHeader title="JFM Contributors" />
            <JfmContributors />
            <Footer />
        </Layout>
    );
};

export default TeamPage;
